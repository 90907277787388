<template>
  <section>
    <LoadingIndicator v-if="isLoading" />
    <div v-else class="container-xs text-center md:pt-2-half">
      <h1 class="mb-half">
        {{ userProfile.firstName }} {{ userProfile.lastName }}
      </h1>
      <div class="heading-2 mb-half">
        {{ $t('DateOfBirth') }}: {{ userProfile.dob }}
      </div>
      <div class="heading-2 mb-1">{{ formattedPhone }}</div>
      <p class="body-1">{{ $t('AccountCreatedInstructions') }}</p>
      <p class="body-1 mb-quarter">{{ $t('ArriveInstructions') }}</p>
      <p>
        <strong>{{ appointmentTime }}</strong>
      </p>
      <address class="my-1-half">
        <h3 class="mb-quarter">{{ appointmentCenter.name }}</h3>
        <span class="mb-half">{{ appointmentCenter.address }}</span
        ><br />
        <span
          >{{ appointmentCenter.city }}, {{ appointmentCenter.state }}
          {{ appointmentCenter.zip }}</span
        >
      </address>
      <a :href="mapsLink" target="_blank" class="button large mb-3">{{
        $t('Directions')
      }}</a>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { parseISO } from 'date-fns';
import { formatDate } from '@/helpers/dates';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  name: 'AccountCreated',
  components: {
    LoadingIndicator,
  },
  computed: {
    ...mapState(['isLoading', 'userProfile', 'currentTestingCenter', 'locale']),
    formattedPhone: function () {
      const { phone } = this.userProfile;
      // group the phone number
      const groups = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (groups) {
        // then format the groups
        return `(${groups[1]}) ${groups[2]}-${groups[3]}`;
      }
      return null;
    },
    appointmentTime: function () {
      const time = this.userProfile.appointments[0].time;
      return (
        time && formatDate(parseISO(time), 'HH:mm a EEEE MMMM do', this.locale)
      );
    },
    appointmentCenter: function () {
      return this.userProfile.appointments[0].testingCenter;
    },
    mapsLink: function () {
      const { address } = this.appointmentCenter;
      const city = this.appointmentCenter.city || '';
      const state = this.appointmentCenter.state || '';
      const zip = this.appointmentCenter.zip || '';
      return `http://maps.google.com/maps?q=${address}+${city}+${state}+${zip}`;
    },
  },
});
</script>
