<template>
  <section>
    <AccountCreated />
  </section>
</template>

<script>
import Vue from 'vue';
import AccountCreated from '@/components/AccountCreated/AccountCreated.vue';

export default Vue.extend({
  name: 'AccountCreatedPage',
  components: {
    AccountCreated,
  },
});
</script>
